import * as React from "react"
import Website from "@src/components/wrappers/website"
import Nav from "@src/components/marketing/headerAlt"
import Footer from "@components/marketing/footer"
import { Link } from "gatsby"

const seo = {
    url: "/privacy-and-security",
    title: "Privacy & Security • Sheetsync",
    description: "We believe privacy is a fundamental human right. Your privacy and security are key priorities at Sheetsync, and we take the responsibility of protecting you and your data extremely seriously."
}

export default class PrivacyAndSecurity extends React.Component {
    render() {
        return (
            <Website seo={seo} marketing>

                <Nav />
                <div className="text-center px-6 py-10 sm:py-12 md:py-16">
                    <h1 className="mx-auto text-gray-900 font-semibold leading-tighter text-3xl sm:text-5xl md:text-55xl lg:text-55xl">
                        Privacy and Security at<br />Sheetsync
    			    </h1>
                    <p className="text-gray-700 text-md mt-4 sm:mt-6 mx-auto max-w-lg sm:text-lg sm:max-w-xl md:text-xl md:max-w-2xl">
                        We believe privacy is a fundamental human right. Your privacy and security are key priorities at Sheetsync, and we take the responsibility of protecting you and your data extremely seriously.
    		        </p>
                </div>

                <div className="max-w-2xl mx-auto p-6 mb-10 sm:mb-12 md:mb-16">
                    <div>
                        <p className="basis font-semibold text-xl sm:text-2xl mb-2 sm:mb-4">Sheetsync does not sell your data.</p>
                        <p className="text-base sm:text-lg">Sheetsync makes money only via your subscription. We do not sell any of your data, and we are committed to never doing this. Read more in our <Link to="/privacy" className="text-blue-800 hover:underline">privacy policy</Link>.</p>
                    </div>
                    <div className="pt-8 sm:pt-12">
                        <p className="basis font-semibold text-xl sm:text-2xl mb-2 sm:mb-4">Sheetsync links to your banks securely via Plaid.</p>
                        <p className="text-base sm:text-lg">
                            <a href="https://plaid.com/overview-privacy/" target="_blank" rel="noopener noreferrer" className="text-blue-800 hover:underline">Plaid</a> is the leading software provider that enables anyone to connect their financial accounts to apps and services like Sheetsync. Nearly 1 out of 4 Americans have connected their bank accounts with Plaid - they are a reputable company trusted by millions. Read more about <a href="https://plaid.com/security/" className="text-blue-800 hover:underline" target="_blank" rel="noopener noreferrer">security</a> and <a href="https://plaid.com/overview-privacy/" className="text-blue-800 hover:underline" target="_blank" rel="noopener noreferrer">privacy</a> at Plaid.
                        </p>
                    </div>
                    <div className="pt-8 sm:pt-12">
                        <p className="basis font-semibold text-xl sm:text-2xl mb-2 sm:mb-4">Sheetsync cannot see your banking credentials.</p>
                        <p className="text-base sm:text-lg">
                            Your banking credentials are securely sent directly to Plaid, so Sheetsync never sees your credentials. Once you have securely connected your account via Plaid, Sheetsync receives a read-only token corresponding to your account.
                        </p>
                    </div>
                    <div className="pt-8 sm:pt-12">
                        <p className="basis font-semibold text-xl sm:text-2xl mb-2 sm:mb-4">Sheetsync cannot move money at your banks.</p>
                        <p className="text-base sm:text-lg">
                            Sheetsync has read-only access to your linked bank accounts. Sheetsync cannot transfer money or otherwise change your accounts in any way. This means Sheetsync cannot change passwords, create accounts, move money, or make any other changes.
                        </p>
                    </div>
                    <div className="pt-8 sm:pt-12">
                        <p className="basis font-semibold text-xl sm:text-2xl mb-2 sm:mb-4">Your financial data is kept private.</p>
                        <p className="text-base sm:text-lg">
                            Sheetsync respects your privacy, so we will only access your spreadsheets or financial information if you give us explicit permission, like asking us to view your spreadsheet during a support request.
                        </p>
                    </div>
                    <div className="pt-8 sm:pt-12">
                        <p className="basis font-semibold text-xl sm:text-2xl mb-2 sm:mb-4">Your data is encrypted.</p>
                        <p className="text-base sm:text-lg">
                            Our platform uses a secure SSL connection, and your data is protected using AES-256 encryption on <a href="https://cloud.google.com/security" target="_blank" rel="noopener noreferrer" className="text-blue-800 hover:underline">Google Cloud</a>.
                        </p>
                    </div>
                    <div className="pt-8 sm:pt-12">
                        <p className="basis font-semibold text-xl sm:text-2xl mb-2 sm:mb-4">The privacy and security of you and your data is incredibly important to us.</p>
                        <p className="text-base sm:text-lg">
                            If you ever have any questions or concerns, please don't hesitate to <a href="mailto:support@getsheetsync.com" target="_blank" rel="noopener noreferrer" className="text-blue-800 hover:underline">contact us</a>. We're more than happy to chat!
                        </p>
                    </div>
                </div>
                <Footer />
            </Website>
        )
    }
}
